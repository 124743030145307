var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticClass: "helpCenter"
    },
    [
      _vm._l(_vm.question, function(content, index) {
        return _c(
          "div",
          { key: index, staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(content.title))
            ]),
            _vm._l(content.items, function(childItem, childIndex) {
              return _c(
                "div",
                {
                  key: childIndex,
                  staticClass: "child-content",
                  on: {
                    click: function($event) {
                      return _vm.goDetail(childItem)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "box" }, [
                    _c("div", { staticClass: "left" }, [
                      _vm._v(_vm._s(childItem.title))
                    ]),
                    _vm._m(0, true)
                  ])
                ]
              )
            })
          ],
          2
        )
      }),
      _vm.load && _vm.question.length < 1
        ? _c("div", { staticClass: "body2" }, [
            _c("img", {
              staticClass: "nullimg",
              attrs: { src: require("../../assets/images/null.png") }
            }),
            _c("div", { staticClass: "nullworld" }, [_vm._v("暂无相关内容")])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _c("img", { attrs: { src: require("../../assets/images/jiantou.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }