<template>
    <div class="helpCenter" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="box" v-for="(content, index) in question" :key="index">
            <div class="title">{{ content.title }}</div>
            <div
                class="child-content"
                @click="goDetail(childItem)"
                v-for="(childItem, childIndex) in content.items"
                :key="childIndex"
            >
                <div class="box">
                    <div class="left">{{ childItem.title }}</div>
                    <div class="right">
                        <img src="../../assets/images/jiantou.png" />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="load && question.length < 1 " class="body2">
            <img src="../../assets/images/null.png" class="nullimg" />
            <div class="nullworld">暂无相关内容</div>
        </div>
    </div>
</template>

<script>
import { getGuideUseList } from "../../api/index";
import axios from 'axios'
import { Loading } from 'element-ui';

export default {
    components: {},
   
    data() {
        return {
            //问题列表
            question: [], //问题列表
            load: false,
            fullscreenLoading:true,
        };
    },
    created() {
        // this.jjWebChannel()
    },
    mounted() {
        window.jjWebChannel = this.jjWebChannel;
        window.getMindexQueList = this.getQueList;
    },
    methods: {
        // openFullScreen1() {
        //     this.fullscreenLoading = true;
        //     setTimeout(() => {
        //     this.fullscreenLoading = false;
        //     }, 2000);
        // },
        jjWebChannel(headdata){
            // headdata 格式
            // headdata =  '{ "scheme":"jjlink://jaguar.job.com/app/health", "data":{ "x-jaguar-device-version-core":"17","Accept-Language":"en-MX", "x-jaguar-time-zone-id":"Asia/Shanghai","x-jaguar-device-id":"fb7176036a5461ab"}}'
            if(localStorage.getItem('Accept-Language')){
                localStorage.removeItem('Accept-Language')
            }
            if(typeof headdata != 'object'){
                headdata = JSON.parse(headdata)
            }
            console.log('data:',headdata,typeof headdata)
            console.log('data:',headdata['data'])
            console.log('data:',headdata.data)
            if(headdata){
                if(headdata['data']){
                    if(headdata['data']['Accept-Language']){
                        localStorage.setItem('Accept-Language',headdata['data']['Accept-Language'])
                    }
                }
            }
            this.getQueList();
        },
        
        //跳转问题详情
        goDetail(item) {
            let  n = item.contentUrl.indexOf('id=')
            let id = item.contentUrl.substr(n+3)
            this.$router.push({path: "/mdetail",query: {id: id }});
            // window.location.href = item.contentUrl;
        },

        getQueList() {
            getGuideUseList().then((res) => {
                if (res.code == "200") {
                    this.question = res.result.items;
                    document.title = res.result.title
                }
                // 加载状态结束
                this.load = true;
                this.fullscreenLoading = false;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.helpCenter {
    width: 100%;
    .title {
        padding: .3rem .30rem;
        font-size: .5rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: .7rem;
    }
    .child-content {
        background: #fff;
        .box {
            display: flex;
            align-items: center;
            justify-items: center;
            padding: .34rem .30rem;
            border-bottom: 0.01rem solid #edeff1;
            font-size: .4rem;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #171e27;
            line-height: .6rem;
            .left {
                flex: 1;
            }
            .right {
                width: .62rem;
                img {
                    width: .32rem;
                }
            }
        }
    }
    .body2 {
        text-align: center;
    }
}
</style>
